import { ClassProvider } from '@angular/core';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

const APPLE_PATTERN = /(mac|iphone|ipod|ipad)/i;
const EVENT_PATTERN = /^click(\.(shift|alt|ctrl)){1,3}$/;

/**
 * This solution is taken from here: https://stackblitz.com/edit/angular-click-modifiers?file=src%2Fapp%2Fclick-modifiers.plugin.ts.
 */

/**
 * Click modifiers plugin.
 * This plugin allows to handle Click + Key actions.
 * @example
 * ```html
 * <!-- Handle Shift + Click action. -->
 * <button (click.shift)="handleClick($event)">Button</button>
 *
 * <!-- Handle Alt + Click action. -->
 * <button (click.shift)="handleClick($event)">Button</button>
 *
 * <!-- Handle Shift + Ctrl + Click action. -->
 * <button (click.shift.ctrl)="handleClick($event)">Button</button>
 * ```
 */
export class ClickModifiersPlugin {

  /**
   * Add event listener.
   * @param target Target.
   * @param eventName Event name.
   * @param originalHandler Original handler.
   */
  public addEventListener(
    target: HTMLElement,
    eventName: string,
    originalHandler: (value: Event) => void,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  ): Function {
    const isApple = APPLE_PATTERN.test(navigator.userAgentData?.platform ?? navigator.platform);
    const [_name, ...keys] = eventName.split('.');
    const modifiers = keys.map(
      key => isApple && key === 'ctrl' ? 'metaKey' : `${key}Key` as keyof MouseEvent,
    );

    const subscription = fromEvent<MouseEvent>(target, 'click').pipe(
      filter(event => modifiers.every(modifier => event[modifier])),
    )
      .subscribe(originalHandler);

    return () => subscription.unsubscribe();
  }

  /**
   * Is event supported.
   * @param eventName Event name.
   */
  public supports(eventName: string): boolean {
    return EVENT_PATTERN.test(eventName);
  }
}

/** Function to provide event binding plugin. */
export const clickModifiersPluginProvider: ClassProvider = {
  provide: EVENT_MANAGER_PLUGINS,
  useClass: ClickModifiersPlugin,
  multi: true,
};

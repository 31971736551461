import { enableProdMode, importProvidersFrom } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';

import { AppConfig } from '@dartsales/common/core/services/app.config';
import { materialProviders } from '@dartsales/common/shared/material-providers';
import { popoverProviders } from '@dartsales/common/shared/popover-providers';
import { pipesProviders } from '@dartsales/common/shared/pipes/pipes-providers';
import { httpInterceptorProviders } from '@dartsales/common/shared/http-interceptor-providers';

import { WebAppConfig } from './app/features/shared/web-app.config';
import { AppComponent } from './app/app.component';
import { authProviders } from './app/features/auth/auth-providers';
import { provideWebAppRoutes } from './app/web-route-paths';
import { appRoutes } from './app/app.routes';
import { webSharedProviders } from './app/features/shared/web-shared-providers';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    importProvidersFrom(MatNativeDateModule),
    ...httpInterceptorProviders,
    ...materialProviders,
    ...popoverProviders,
    ...pipesProviders,
    ...authProviders,
    ...webSharedProviders,
    { provide: AppConfig, useClass: WebAppConfig },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideWebAppRoutes(),
    provideRouter(appRoutes),
  ],
})
  .catch(err => console.error(err));

import { Provider } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

/** Auth global providers. */
export const authProviders: readonly Provider[] = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
      floatLabel: 'always',
      hideRequiredMarker: true,
    },
  },
];

import { inject } from '@angular/core';
import { first, map, of, switchMap } from 'rxjs';
import { CanMatchFn, Route, Router } from '@angular/router';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { PermissionsService } from '../services/permissions.service';
import { CurrentUserService } from '../services/current-user.service';
import { UserPermission } from '../enums/user-permission';

/**
 * Guard that checks user role.
 * Available roles should be in data.permissionType field. If none userRoles specified, no users can enter except Global Admin.
 * @example
 * {
 *   path: 'properties',
 *   canMatch: [permissionsGuard()],
 *   data: {
 *     permissionType: Permission.CRUDResource,
 *   },
 *   ...
 * }
 */
export function permissionsGuard(): CanMatchFn {
  return (route: Route) => {
    const userService = inject(CurrentUserService);
    const permissionsService = inject(PermissionsService);
    const router = inject(Router);
    const routePaths = injectWebAppRoutes();
    return userService.currentUser$.pipe(
      first(),
      switchMap(user => {
        const permissionType: UserPermission | undefined = route.data?.['permissionType'];

        if (user?.isGlobalAdmin) {
          return of(true);
        }

        if (user == null || permissionType == null) {
          return of(false);
        }

        return permissionsService.hasPermission([permissionType]);
      }),
      map(value => value ? true : router.createUrlTree([routePaths.permissionError.url])),
    );
  };
}

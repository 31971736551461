import { EnvironmentProviders } from '@angular/core';
import { popperVariation, provideTippyConfig } from '@ngneat/helipopper';

/** Popover global providers. */
export const popoverProviders: readonly EnvironmentProviders[] = [
  provideTippyConfig({
    defaultVariation: 'popperBorder',
    variations: {
      popperBorder: { ...popperVariation, placement: 'right', theme: 'light-border', animation: 'scale' },
    },
  }),
];

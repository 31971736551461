import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from '../core/interceptors/auth.interceptor';
import { RefreshTokenInterceptor } from '../core/interceptors/refresh-token.interceptor';

/** Http interceptor providers. */
export const httpInterceptorProviders = [
  // The refresh interceptor should be before the auth interceptor, otherwise refreshed bearer would not be updated
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { CurrentUserService } from '../services/current-user.service';

/** Guard prevents a current user from accessing a route in case they are authorized. */
export function authorizedGuard(): CanMatchFn {
  return () => {
    const userService = inject(CurrentUserService);
    const router = inject(Router);
    const routePaths = injectWebAppRoutes();
    return userService.isAuthorized$.pipe(
      map(isAuthorized => (isAuthorized ? router.parseUrl(routePaths.home.url) : true)),
    );
  };
}

import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map, first } from 'rxjs';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { UserProfile } from '../models/user/user-profile';
import { CurrentUserService } from '../services/current-user.service';
import { ALLOWED_ROLES } from '../utils/constants';

/** Guard prevents a current user from accessing Sales if he doesn't have necessary permissions. */
export function salesAppGuard(): CanMatchFn {
  return () => {
    const currentUserService = inject(CurrentUserService);
    const router = inject(Router);
    const routePaths = injectWebAppRoutes();
    return currentUserService.currentUser$.pipe(
      map(user => {
        if (user !== null && (user.isGlobalAdmin === true || checkUserHasAllowedRole(user))) {
          return true;
        }
        return router.createUrlTree([routePaths.accessError.url]);
      }),
      first(),
    );
  };
}

/**
 * Checks if user has allowed role.
 * @param user User.
 */
function checkUserHasAllowedRole(user: UserProfile): boolean {
  return user.organizations.some(org => org.hasAllowedRoles(ALLOWED_ROLES));
}

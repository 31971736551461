import { ClassProvider } from '@angular/core';
import { EVENT_MANAGER_PLUGINS, EventManager } from '@angular/platform-browser';
import { EMPTY, Observable, concat, defer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

/**
 * Plugin to bind Observables to Angular `@HostBinding`.
 * The implementation was taken from here: https://stackblitz.com/edit/angular-host-binding-observable?file=src%2Fapp%2Fplugin.ts.
 * Also you can find some details about the solution in the article:
 * https://medium.com/its-tinkoff/making-hostbinding-work-with-observables-23396f3b8aea.
 */
export class BindEventPlugin {
  private readonly modifier = '$';

  private readonly manager!: EventManager;

  /**
   * Check whether event is supported.
   * @param event Event.
   */
  public supports(event: string): boolean {
    return event.includes(this.modifier);
  }

  /**
   * Add event listener to HTML element.
   * @param element Element.
   * @param event Event.
   */
  public addEventListener(
    element: HTMLElement & Record<string, Observable<unknown>>,
    event: string,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  ): Function {
    element[event] = element[event] || EMPTY;

    const method = this.getMethod(element, event);
    const zone = this.manager.getZone().onStable;
    const sub = concat(
      zone.pipe(takeWhile(() => element[event] === EMPTY)),
      defer(() => element[event]),
    ).subscribe(method);

    return () => sub.unsubscribe();
  }

  private getMethod(
    element: HTMLElement & Record<string, unknown>,
    event: string,
  ): (v: unknown) => void {
    const [, key, value, unit = ''] = event.split('.');

    if (event.endsWith('.attr')) {
      return v =>
        v === null ?
          element.removeAttribute(key) :
          element.setAttribute(key, String(v));
    }

    if (key === 'class') {
      return v => element.classList.toggle(value, !!v);
    }

    if (key === 'style') {
      return v => element.style.setProperty(dasharize(value), `${v}${unit}`);
    }

    return v => (element[key] = v);
  }
}

/** Function to provide event binding plugin. */
export const bindEventPluginProvider: ClassProvider = {
  provide: EVENT_MANAGER_PLUGINS,
  useClass: BindEventPlugin,
  multi: true,
};

/**
 * Function to replace camelCase with kebab case.
 * @param camel Input in camelCase.
 */
function dasharize(camel: string): string {
  return camel.replace(/[a-z][A-Z]/g, letterLetter => `${letterLetter[0]}-${letterLetter[1].toLowerCase()}`);
}

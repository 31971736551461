import { Injectable, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/** Custom icon data. */
type IconData = {

  /** Icon name. */
  readonly name: string;

  /** Path to icon. */
  readonly path: string;
};

/** Icon service.*/
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private readonly matIconRegistry = inject(MatIconRegistry);

  private readonly domSanitizer = inject(DomSanitizer);

  private readonly icons: readonly IconData[] = [
    {
      name: 'template',
      path: 'assets/icons/template.svg',
    },
    {
      name: 'resource',
      path: 'assets/icons/resource.svg',
    },
    {
      name: 'bidCategories',
      path: 'assets/icons/bid-categories.svg',
    },
    {
      name: 'user',
      path: 'assets/icons/user.svg',
    },
    {
      name: 'wrench',
      path: 'assets/icons/wrench.svg',
    },
    {
      name: 'briefcase',
      path: 'assets/icons/briefcase.svg',
    },
    {
      name: 'systemClassification',
      path: 'assets/icons/system-classification.svg',
    },
    {
      name: 'expenseType',
      path: 'assets/icons/payments.svg',
    },
    {
      name: 'fileImage',
      path: 'assets/icons/file-image.svg',
    },
    {
      name: 'filePDF',
      path: 'assets/icons/file-pdf.svg',
    },
    {
      name: 'fileArchive',
      path: 'assets/icons/file-archive.svg',
    },
    {
      name: 'extend',
      path: 'assets/icons/extend.svg',
    },
    {
      name: 'filter',
      path: 'assets/icons/filter.svg',
    },
    {
      name: 'file',
      path: 'assets/icons/file.svg',
    },
    {
      name: 'comments',
      path: 'assets/icons/comments.svg',
    },
    {
      name: 'checkmark-circle',
      path: 'assets/icons/checkmark-circle.svg',
    },
    {
      name: 'warning-circle',
      path: 'assets/icons/warning-circle.svg',
    },
    {
      name: 'port-stacked',
      path: 'assets/icons/port-stacked.svg',
    },
    {
      name: 'group',
      path: 'assets/icons/group.svg',
    },
    {
      name: 'ungroup',
      path: 'assets/icons/ungroup.svg',
    },
    {
      name: 'folder-move',
      path: 'assets/icons/folder-move.svg',
    },
    {
      name: 'copy',
      path: 'assets/icons/copy.svg',
    },
    {
      name: 'filter-list',
      path: 'assets/icons/filter-list.svg',
    },
    {
      name: 'part',
      path: 'assets/icons/part.svg',
    },
    {
      name: 'part-readonly',
      path: 'assets/icons/part-readonly.svg',
    },
    {
      name: 'symbol',
      path: 'assets/icons/symbol.svg',
    },
    {
      name: 'arrow-upward',
      path: 'assets/icons/arrow-upward.svg',
    },
  ];

  /** Register icons. */
  public registerIcons(): void {
    this.icons.forEach(({ name, path }) => this.registerIcon(name, path));
  }

  private registerIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path),
    );
  }
}

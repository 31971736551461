import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { LicenseManager } from 'ag-grid-enterprise';

import { IconsService } from '@dartsales/common/core/services/icons.service';
import { SnackbarNotificationsService } from '@dartsales/common/core/services/snackbar-notifications.service';
import { AppConfig } from '@dartsales/common/core/services/app.config';

/** Root component. */
@Component({
  selector: 'dartsalesw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {

  private readonly snackbarNotificationsService = inject(SnackbarNotificationsService);

  private readonly iconsService = inject(IconsService);

  private readonly configService = inject(AppConfig);

  private readonly destroyRef = inject(DestroyRef);

  public constructor() {
    this.iconsService.registerIcons();
    LicenseManager.setLicenseKey(this.configService.agGridLicenseKey);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToNotification();
  }

  private subscribeToNotification(): void {
    this.snackbarNotificationsService.notification$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }
}

import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { CurrentUserService } from '../services/current-user.service';

/** Guard prevents user from accessing if a user is not logged in. */
export function unauthorizedGuard(): CanMatchFn {
  return () => {
    const userService = inject(CurrentUserService);
    const router = inject(Router);
    const routePaths = injectWebAppRoutes();
    const url = router.getCurrentNavigation()?.initialUrl.toString();
    return userService.isAuthorized$.pipe(
      map(isAuthorized => {
        if (isAuthorized) {
          return true;
        }

        return router.createUrlTree([routePaths.auth.children.login.url], {
          queryParams: url ? { next: url } : undefined,
        });
      }),
    );
  };
}
